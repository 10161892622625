import * as React from "react";

import {
  Slide,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  DialogProps,
} from "@material-ui/core";

import { TransitionProps } from "@material-ui/core/transitions";

import PropTypes from "prop-types";

import { Button } from "components";

import styles from "assets/jss/styles/components/dialogStyle";

const useStyles = makeStyles<typeof styles>(styles);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CustomDialogProps extends DialogProps {
  noCancelButton?: boolean;
  confirmProps?: object;
  confirmText: string;
  cancelText?: string;
  content: any;
  onCancel?: () => void;
  onConfirm?: () => void;
  dialogColor?: string;
  confirmColor?: string;
  cancelColor?: string;
  dialogActions?: any;
  open: boolean;
  fullWidth?: boolean;
  color?: string;
}

export default function Dialog(props: CustomDialogProps) {
  const classes = useStyles();

  const {
    maxWidth,
    fullWidth,
    dialogColor,
    confirmColor,
    cancelColor,
    confirmText,
    cancelText,
    noCancelButton,
    confirmProps,
    dialogActions,
    open,
    color,
    title,
    content,
    onCancel,
    onConfirm,
    onClose,
    ...rest
  } = props;

  const descriptionElementRef: any = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <MuiDialog
        classes={{
          paper: `${classes[dialogColor + "DialogColor"]}`,
        }}
        scroll="paper"
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onClose={onClose}
        {...rest}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={`${classes.dialogTitle} ${classes[color + "Title"]}`}
        >
          {title}
        </DialogTitle>
        <DialogContent
          dividers
          classes={{
            dividers: `${classes.dividers} ${classes[dialogColor + "Divider"]}`,
          }}
        >
          {content}
        </DialogContent>
        <DialogActions
          className={
            classes.dialogTitleFooter + " " + classes.dialogTitleFooterCenter
          }
        >
          {dialogActions}
          {noCancelButton ? null : (
            <Button
              color={cancelColor}
              textColor={color}
              className={classes.button}
              size="sm"
              onCancel={onCancel}
            >
              {cancelText}
            </Button>
          )}

          <Button
            color={confirmColor}
            textColor={color}
            className={classes.button}
            size="sm"
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
}

Dialog.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs", false]),
  fullWidth: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  noCancelButton: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.node,
  onCancel: PropTypes.object,
  confirmColor: PropTypes.string,
  cancelColor: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  color: PropTypes.oneOf(["primary", "secondary", "gray"]),
  dialogColor: PropTypes.oneOf(["primary", "secondary", "gray"]),
};
