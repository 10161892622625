import { Dispatch } from "redux";

export const SNACKBAR_SUCCESS = "snackbar_success";
export const SNACKBAR_CLEAR = "snackbar_clear";

export function showSnackbar(message: object) {
  return (dispatch: Dispatch) => {
    dispatch({ type: SNACKBAR_SUCCESS, payload: message });
  };
}

export function clearSnackbar() {
  return (dispatch: Dispatch) => {
    dispatch({ type: SNACKBAR_CLEAR });
  };
}
