import { FormControlLabel, Switch } from "@material-ui/core";
import React from "react";

const RegularButton = React.forwardRef((props: any, ref) => {
  const { muiClasses, formControlLabelProps, label, color, ...rest } = props;

  return (
    <FormControlLabel
      label={label}
      control={
        <Switch ref={ref} color={color} classes={muiClasses} {...rest} />
      }
      {...formControlLabelProps}
    />
  );
});

export default RegularButton;
