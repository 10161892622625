import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store";
import PropTypes from "prop-types";

import { Redirect, Route } from "react-router-dom";

import AuthLayout from "layouts/Auth";
import AppLayout from "layouts/App";
import { RouteWrapperProps } from "models/route-wrapper";
import { getUser } from "services/auth";

const UnconnectedRouteWrapper: React.FC<RouteWrapperProps & HeaderProps> = ({
  component: Component,
  isPrivate,
  admin,
  authenticated,
  ...rest
}) => {
  const { role_id }: ReturnType<typeof getUser> = { ...getUser() };

  if (authenticated && admin && role_id !== 1) {
    return <Redirect to="/dashboard" />;
  }

  if (!authenticated && isPrivate) {
    return <Redirect to="/" />;
  }

  if (authenticated && !isPrivate) {
    return <Redirect to="/home" />;
  }

  const Layout = authenticated ? AppLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return { authenticated: state.auth.authenticated };
};

const connector = connect(mapStateToProps);

type HeaderProps = ConnectedProps<typeof connector>;

export default connector(UnconnectedRouteWrapper);

UnconnectedRouteWrapper.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string,
};
UnconnectedRouteWrapper.defaultProps = {
  isPrivate: false,
  admin: false,
};
