import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";

import { IoMdAdd, IoMdRemove } from "react-icons/io";

import styles from "assets/jss/styles/components/accordionStyle";
import { PropsInterface } from "models/components/accordion";

const useStyles = makeStyles<typeof styles>(styles);

export default function Accordion(props: PropsInterface) {
  const [expanded, setExpanded] = React.useState<number | false>(props.active);
  const { collapses, color, bgColor } = props;
  const classes = useStyles();

  const accordionClasses = classNames({
    [classes[color]]: color,
    [classes[bgColor + "Background"]]: bgColor,
    [classes["accordionRoot"]]: true,
    [classes["accordion"]]: true,
  });
  const handleChange = (panel: number) => (
    _: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : -1);
  };

  return (
    <div className={classes.root}>
      {collapses.map((prop, key: number) => {
        return (
          <MuiAccordion
            style={prop.disabled ? { pointerEvents: "none" } : {}}
            classes={{
              root: accordionClasses,
            }}
            key={key}
            expanded={prop.disabled ? false : expanded === key}
            onChange={handleChange(key)}
          >
            <AccordionSummary
              expandIcon={
                expanded === key ? (
                  <IoMdRemove className={classes[color]} />
                ) : (
                  <IoMdAdd className={classes[color]} />
                )
              }
              aria-controls="accordion-content"
              id={`accordion-${key}`}
              classes={{ content: classes.accordionSummaryContent }}
            >
              {typeof prop.title === "string" ? (
                <Typography className={classes.heading}>
                  {prop.title}
                </Typography>
              ) : (
                prop.title
              )}
            </AccordionSummary>
            <Divider className={classes.divider} />
            <AccordionDetails className={classes[color]}>
              {prop.content}
            </AccordionDetails>
          </MuiAccordion>
        );
      })}
    </div>
  );
}

Accordion.defaultProps = {
  active: -1,
};

Accordion.propTypes = {
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      content: PropTypes.node,
    })
  ).isRequired,
};
