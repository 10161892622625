import * as React from "react";

import { TableActions, TableBody } from "hoc";
import { RiHeartAddFill } from "react-icons/ri";
import { Row } from "react-table";
import { Dialog, Input } from "components";
import { Grid } from "@material-ui/core";
import { FormInputs, RowProps } from "models/views/service";
import { useForm } from "react-hook-form";
import api from "services/api";

const initialValues = {
  id: null,
  name: null,
  duration_time: null,
};

export default function Service() {
  const [service, setService] = React.useState<FormInputs>();
  const [open, setOpen] = React.useState(false);
  const addService = () => {
    setOpen(true);
  };
  const deleteService = async (original: RowProps) => {
    await api.delete(`services/${original.id}`).then(() => getRecords());
  };
  const editService = (original: RowProps) => {
    setService(original);
    setOpen(true);
  };
  const [services, setServices] = React.useState<any>([]);
  const [columns] = React.useState([
    {
      Header: "Nome",
      Cell: ({ row }: { row: Row<RowProps> }) => row.original.name,
    },
    {
      Header: "Validade(meses)",
      Cell: ({ row }: { row: Row<RowProps> }) => row.original.duration_time,
    },
    {
      Header: "Ações",
      id: "actions",
      Cell: ({ row }: { row: Row<RowProps> }) => (
        <TableActions
          editProps={{ onClick: () => editService(row.original) }}
          deleteProps={{ onClick: () => deleteService(row.original) }}
        />
      ),
    },
  ]);

  const getRecords = () =>
    api.get("services").then(({ data }) => setServices(data));

  React.useEffect(() => {
    getRecords();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setService(initialValues);
  };

  const onSubmit = (data: FormInputs) => {
    if (service?.id)
      api.put(`services/${service.id}`, data).then(() => getRecords());
    else api.post("services", data).then(() => getRecords());

    handleClose();
  };

  const ServiceForm = ({
    defaultValues,
  }: {
    defaultValues: typeof service;
  }) => {
    const { register, handleSubmit } = useForm<FormInputs>({
      defaultValues,
    });

    return (
      <Dialog
        width="md"
        fullWidth
        confirmText="Confirmar"
        noCancelButton
        title={`${service?.id ? "editar" : "adicionar"} serviço`}
        color="primary"
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                name="name"
                required
                inputRef={register({ required: true })}
                label="Nome"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                name="duration_time"
                required
                inputRef={register({ required: true })}
                label="Validade(em meses)"
                type="number"
              />
            </Grid>
          </Grid>
        }
        onClose={handleClose}
        onConfirm={handleSubmit(onSubmit)}
        open={open}
      />
    );
  };

  return (
    <React.Fragment>
      <ServiceForm defaultValues={service} />
      <TableBody
        buttonProps={{
          onClick: addService,
        }}
        buttonText="adicionar serviço"
        icon={RiHeartAddFill}
        columns={columns}
        data={services}
      />
    </React.Fragment>
  );
}
