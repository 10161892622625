import { combineReducers } from "redux";
import authReducer from "./modules/auth/reducer";
import uiReducer from "./modules/ui/reducer";

export type CommonAction = {
  type: string | number;
  payload?: any;
};

export const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
