import * as React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  makeStyles,
} from "@material-ui/core";

import classNames from "classnames";

import { usePagination, useSortBy, useTable } from "react-table";

import styles from "assets/jss/styles/components/tableStyle";
import { TableProps } from "models/components/table";

const useStyles = makeStyles<typeof styles>(styles);

const CustomTable = (props: TableProps) => {
  const classes = useStyles();

  const { columns, data } = props;

  const {
    getTableProps,
    headerGroups,
    page,
    gotoPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  }: any = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  const handleChangePage = (_event: any, newPage: number) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(Number(event.target.value));
  };

  const isSorted = (column: {
    isSorted: boolean;
    isSortedDesc: boolean;
    isSortedAsc: boolean;
  }) => {
    const sort = classNames({
      [classes.sort]: true,
      [classes.sortDesc]: column.isSortedDesc,
      [classes.sortAsc]: !column.isSortedDesc,
    });

    return column.isSorted ? sort : "";
  };

  return (
    <React.Fragment>
      <MuiTable className={classes.table} {...getTableProps()}>
        <TableHead className={classes.tableRowHead}>
          {headerGroups.map((headerGroup: any) => (
            <TableRow
              className={classes.tableRowHead}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column: any) => (
                <TableCell
                  className={classes.tableHeadCell}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span className={isSorted(column)} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody className={classes.tableRowBody}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <TableRow
                className={classes.tableRowBody}
                hover
                {...row.getRowProps()}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <TableCell
                      className={classes.tableCell}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
      <TablePagination
        labelRowsPerPage={
          page.length !== 0 ? "Linhas por página" : "Nenhum registro encontrado"
        }
        rowsPerPageOptions={[5, 10, 25]}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
        }
        component="div"
        count={data.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

function Table(props: TableProps) {
  const columns = React.useMemo(() => props.columns, [props.columns]);
  const data = React.useMemo(() => props.data, [props.data]);

  return <CustomTable columns={columns} data={data} />;
}

export default Table;
