import { Dispatch } from "redux";
import api from "services/api";
import { signIn, setUser } from "services/auth";
import { createBrowserHistory } from "history";

import { RouteComponentProps } from "react-router-dom";

export const AUTHENTICATED = "authenticated_user";
export const UNAUTHENTICATED = "unauthenticated_user";
export const AUTHENTICATION_ERROR = "authentication_error";

interface LoginInterface extends RouteComponentProps {
  email: string;
  password: string;
}

const history = createBrowserHistory();

type Props = LoginInterface;

export function login(props: Props) {
  const { email, password } = props;
  return async (dispatch: Dispatch) => {
    await api
      .post("authenticate", { email, password })
      .then(({ data }) => {
        window.location.reload();
        dispatch({ type: AUTHENTICATED });
        signIn(data.token);
        setUser(data.user);
        history.push("/home");
      })
      .catch(() =>
        dispatch({
          type: AUTHENTICATION_ERROR,
          payload: "usuário ou senha inválida",
        })
      );
  };
}

export function logout() {
  localStorage.clear();
  history.push("/")
  return {
    type: UNAUTHENTICATED,
  };
}
