import {
  secondaryColor,
  primaryColor,
  warningColor,
  successColor,
  infoColor,
} from "assets/jss/styles";

const palette = {
  info: {
    main: infoColor[0],
  },
  warning: {
    main: warningColor[0],
  },
  success: {
    main: successColor[0],
  },
  primary: {
    main: primaryColor[0],
    light: primaryColor[3],
  },
  secondary: {
    main: secondaryColor[0],
  },
};

export default palette;
