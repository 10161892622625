import { SNACKBAR_SUCCESS, SNACKBAR_CLEAR } from "./actions";

import { CommonAction } from "store";

type CommonState = {
  successSnackbarMessage: {
    message: string;
    severity: string;
  };
  successSnackbarOpen: boolean;
};

const initialState: CommonState = {
  successSnackbarMessage: {
    message: "",
    severity: "success",
  },
  successSnackbarOpen: false,
};

export default function uiReducer(state = initialState, action: CommonAction) {
  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload,
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };
  }
  return state;
}
