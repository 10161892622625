import * as React from "react";

import styles from "assets/jss/styles/views/loginPageStyle";
import { makeStyles, Grid } from "@material-ui/core";
import { Card, CardBody, CardFooter, Input, Button } from "components";

import { useForm } from "react-hook-form";

import brandImg from "assets/img/neo-logo-lettering.png";
import { IoIosArrowBack } from "react-icons/io";

import { login } from "store/modules/auth/actions";
import { showSnackbar } from "store/modules/ui/actions";
import { connect, useDispatch } from "react-redux";
import { RootState } from "store";
import { LoginProps } from "models/views/pages/login";
import api from "services/api";
import { useParams } from "react-router-dom";

const useStyles = makeStyles<typeof styles>(styles);

const UnconnectedLogin: React.FC<LoginProps> = ({ history, login, error }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params: { token: string } = useParams();
  const [brandImage] = React.useState(brandImg);
  const [forgotPassword, setForgotPassword] = React.useState<boolean>(false);
  const [leftContentBg] = React.useState({
    backgroundColor: "#e8e8e8",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='24' viewBox='0 0 88 24'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='autumn' fill='%23d4d4d4' fill-opacity='0.22'%3E%3Cpath d='M10 0l30 15 2 1V2.18A10 10 0 0 0 41.76 0H39.7a8 8 0 0 1 .3 2.18v10.58L14.47 0H10zm31.76 24a10 10 0 0 0-5.29-6.76L4 1 2 0v13.82a10 10 0 0 0 5.53 8.94L10 24h4.47l-6.05-3.02A8 8 0 0 1 4 13.82V3.24l31.58 15.78A8 8 0 0 1 39.7 24h2.06zM78 24l2.47-1.24A10 10 0 0 0 86 13.82V0l-2 1-32.47 16.24A10 10 0 0 0 46.24 24h2.06a8 8 0 0 1 4.12-4.98L84 3.24v10.58a8 8 0 0 1-4.42 7.16L73.53 24H78zm0-24L48 15l-2 1V2.18A10 10 0 0 1 46.24 0h2.06a8 8 0 0 0-.3 2.18v10.58L73.53 0H78z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  });
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(() => {
    setCardAnimation("");
  }, 700);

  const { register, handleSubmit, setError, errors, reset } = useForm();

  const goBack = () => {
    reset();
    setForgotPassword(false);
    history.push("/");
  };

  const onSubmit = ({ email, password, newPassword, confirmPassword }: any) => {
    if (newPassword !== confirmPassword)
      setError("newPassword", {
        type: "manual",
        message: "A senha informada não confere.",
      });
    else if (params.token)
      api
        .post("/forgot-password/reset", {
          password: newPassword,
          token: params.token,
        })
        .then(() => {
          dispatch(
            showSnackbar({
              message: "Senha alterada com sucesso.",
              severity: "success",
            })
          );
          goBack();
        })
        .catch(() =>
          dispatch(
            showSnackbar({
              message: "Verifique a sua caixa de entrada e clique no link.",
              severity: "error",
            })
          )
        );
    else
      forgotPassword
        ? api
            .post("/forgot-password", { email })
            .then(() =>
              dispatch(
                showSnackbar({
                  message: "Você receberá uma mensagem em instantes.",
                  severity: "success",
                })
              )
            )
            .catch(({ response: { data } }) =>
              dispatch(
                showSnackbar({
                  message: data.message,
                  severity: "error",
                })
              )
            )
        : login({ email, password });
  };

  const LoginFooter = (prop: any) => (
    <React.Fragment>
      <Button color="primary" type="submit">
        {prop.left}
      </Button>
      <Button textColor="primary" onClick={prop.onClick}>
        {prop.right}
      </Button>
    </React.Fragment>
  );

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Card login className={`${classes[cardAnimaton]} ${classes.card}`}>
            <Grid
              container
              direction="row"
              component="form"
              onSubmit={handleSubmit((data) => onSubmit(data))}
            >
              <Grid
                container
                item
                xs={5}
                sm={5}
                md={5}
                lg={5}
                style={leftContentBg}
                className={classes.leftContentImg}
                alignItems="center"
              >
                <CardBody className={classes.leftContent}>
                  <img src={brandImage} width="80%" alt="neo-logo" />
                </CardBody>
              </Grid>

              <Grid item xs={7} sm={7} md={7} lg={7}>
                <CardBody>
                  {!forgotPassword && !params?.token ? (
                    <React.Fragment>
                      <Input
                        required
                        helperText={error}
                        inputRef={register}
                        name="email"
                        type="email"
                        error={!!error}
                        label="E-mail"
                      />
                      <Input
                        required
                        label="Senha"
                        inputRef={register}
                        name="password"
                        type="password"
                      />
                    </React.Fragment>
                  ) : !params?.token ? (
                    <Input
                      required
                      inputRef={register}
                      name="email"
                      label="E-mail"
                      type="email"
                      helperText="Informe o e-mail usado para iniciar sessão."
                    />
                  ) : (
                    <React.Fragment>
                      <Input
                        required
                        name="newPassword"
                        error={!!errors.newPassword}
                        helperText={errors.newPassword?.message}
                        inputRef={register}
                        type="password"
                        label="Nova senha"
                      />
                      <Input
                        required
                        name="confirmPassword"
                        inputRef={register}
                        type="password"
                        label="Confirmar nova senha"
                      />
                    </React.Fragment>
                  )}
                </CardBody>
                <CardFooter>
                  {!forgotPassword && !params?.token ? (
                    <LoginFooter
                      left="Entrar"
                      right="Esqueci a senha"
                      onClick={() => setForgotPassword(true)}
                    />
                  ) : (
                    <LoginFooter
                      left="Enviar"
                      right={
                        <React.Fragment>
                          <IoIosArrowBack /> Voltar
                        </React.Fragment>
                      }
                      onClick={goBack}
                    />
                  )}
                </CardFooter>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return { error: state.auth.error };
};

const mapDispatchToProps = { login, showSnackbar };

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedLogin);
