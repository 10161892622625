import { createStyles, Theme } from "@material-ui/core";
import { primaryColor } from "assets/jss/styles";

const customTabsStyle = createStyles((theme: Theme) => ({
  tabWrapper: {
    transition: "all 300ms linear",
    "&:hover": {
      outline: "none",
      color: `${primaryColor[0]} !important`,
      backfaceVisibility: "hidden",
      transform: "rotate(1deg) scale(1.02)",
      boxShadow: "0 1rem 2rem rgb(0 0 0 / 3%)",
    },
  },
  tabRoot: {
    fontWeight: 400,
    minWidth: "100px",
    textTransform: "none"
  },
  tabIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: "block",
  },
  labelContainer: {
    padding: "0!important",
    color: "inherit",
  },
  contentWrapper: {
    marginTop: "20px",
  },
  tab: {
    borderRadius: "5px",
  },
}));

export default customTabsStyle;
