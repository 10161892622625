import * as React from "react";
import { Grid, SvgIconTypeMap } from "@material-ui/core";
import { Button, Table } from "components";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

import { Column } from "react-table";

interface TableBodyProps {
  buttonText?: string | React.InputHTMLAttributes<HTMLInputElement>;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  afterButton?: any;
  columns: Column<object>[];
  data: object[];
  noButton?: boolean;
  gridContainerProps?: Object;
  buttonProps?: Object;
}

export default function TableBody(props: TableBodyProps) {
  const {
    buttonText,
    columns,
    data,
    buttonProps,
    gridContainerProps,
    afterButton,
    noButton,
  } = props;

  return (
    <Grid container {...gridContainerProps}>
      {!noButton && (
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Button color="primary" textColor="white" {...buttonProps}>
              {props.icon && <props.icon />} {buttonText}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {afterButton}
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Table columns={columns} data={data} />
      </Grid>
    </Grid>
  );
}
