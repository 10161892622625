import {
  primaryColor,
  grayColor,
  secondaryColor,
  blackColor,
  hexToRgb,
  whiteColor,
} from "assets/jss/styles";

import { createStyles, Theme } from "@material-ui/core";

const accordionStyle = createStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "20px",
    "&:before": {
      backgroundColor: "red !important",
    },
  },
  accordionRoot: {
    "&:before": {
      backgroundColor: 'inherit'
    }
  },
  divider: {
    backgroundColor: primaryColor[4],
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  primary: {
    color: primaryColor[0],
  },
  secondary: {
    color: secondaryColor[0],
  },
  primaryBackground: {
    backgroundColor: primaryColor[0],
    "&:hover,&:focus": {
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.2)",
    },
  },
  whiteBackground: {
    backgroundColor: whiteColor,
    "&:hover,&:focus": {
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(whiteColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(whiteColor) +
        ", 0.2)",
    },
  },
  primarySummary: {
    backgroundColor: primaryColor[0],
    "&:hover,&:focus": {
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.2)",
    },
  },
  secondaryBackground: {
    backgroundColor: secondaryColor[0],
    "&:hover,&:focus": {
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(secondaryColor[0]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(secondaryColor[0]) +
        ", 0.2)",
    },
  },
  accordion: {
    borderRadius: "5px",
    marginBottom: "5px",
  },
  accordionExpanded: {
    margin: "0 !important",
  },
  accordionSummary: {
    minHeight: "auto !important",
    backgroundColor: "transparent",
    borderBottom: "1px solid " + grayColor[5],
    padding: "25px 10px 5px 0px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    color: grayColor[2],
    "&:hover": {
      color: primaryColor[0],
    },
  },
  accordionSummaryExpaned: {
    color: primaryColor[0],
    "& $accordionSummaryExpandIcon": {
      [theme.breakpoints.up("md")]: {
        top: "auto !important",
      },
      transform: "rotate(180deg)",
      [theme.breakpoints.down("sm")]: {
        top: "10px !important",
      },
    },
  },
  accordionSummaryContent: {
    display: "unset",
  },
  accordionSummaryExpandIcon: {
    [theme.breakpoints.up("md")]: {
      top: "auto !important",
    },
    transform: "rotate(0deg)",
    color: "inherit",
    position: "absolute",
    right: "20px",
    [theme.breakpoints.down("sm")]: {
      top: "10px !important",
    },
  },
  title: {
    fontSize: "15px",
    fontWeight: "bolder",
    marginTop: "0",
    marginBottom: "0",
    color: "inherit",
  },
  accordionDetails: {
    padding: "15px 0px 5px",
  },
}));

export default accordionStyle;
