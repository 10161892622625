import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import styles from "assets/jss/styles/components/buttonStyle";

const useStyles = makeStyles<typeof styles>(styles);

const RegularButton = React.forwardRef((props: any, ref) => {
  const classes = useStyles();
  const {
    textColor,
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    btnStyle,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes[`noTransform`]]: btnStyle === "no-transform",
    [classes.button]: true,
    [classes[size]]: size,
    [classes[`${textColor}TextColor`]]: textColor,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });
  return (
    <Button {...rest} ref={ref} classes={muiClasses} className={btnClasses}>
      {children}
    </Button>
  );
});

RegularButton.defaultProps = {
  color: "transparent",
  size: "sm",
};

RegularButton.propTypes = {
  textColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
  ]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "gray",
    "danger",
    "rose",
    "white",
    "transparent",
  ]),
  btnStyle: PropTypes.oneOf(["no-transform", "default"]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  children: PropTypes.node,
};

export default RegularButton;
