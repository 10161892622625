import { grayColor, secondaryColor, primaryColor } from "assets/jss/styles";

import { createStyles, Theme } from "@material-ui/core";

const dialogStyle = createStyles((theme: Theme) => ({
  primaryDialogColor: {
    backgroundColor: primaryColor[0],
  },
  secondaryDialogColor: {
    backgroundColor: secondaryColor[0],
  },
  grayDialogColor: {
    backgroundColor: grayColor[0],
  },
  primaryTitle: {
    color: primaryColor[0],
  },
  secondaryTitle: {
    color: secondaryColor[0],
  },
  grayTitle: {
    color: grayColor[0],
  },
  primaryText: {
    color: primaryColor[0],
  },
  secondaryText: {
    color: secondaryColor[0],
  },
  primaryDivider: {
    borderColor: primaryColor[3],
  },
  secondaryDivider: {
    borderColor: secondaryColor[3],
  },
  dialogRoot: {
    overflow: "auto",
    alignItems: "unset",
    justifyContent: "unset",
  },
  dialog: {
    [theme.breakpoints.up("sm")]: {
      margin: "auto",
    },
    borderRadius: "10px",
    marginTop: "100px !important",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content",
  },
  dialogHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px",
  },
  dialogTitle: {
    textAlign: "center",
    margin: "0",
    lineHeight: "1.42857143",
  },

  button: {
    marginRight: "8px",
    fontSize: "16px !important",
    fontWeight: `${600} !important`,
    textTransform: "none !important",
  },
  titleIcon: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogCloseButton: {
    color: grayColor[0],
    marginTop: "-12px",
    WebkitAppearance: "none",
    padding: "0",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
  },
  dialogClose: {
    width: "16px",
    height: "16px",
  },
  dialogText: {
    fontSize: "18px",
  },
  dialogBody: {
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "16px",
    paddingLeft: "24px",
    position: "relative",
    overflow: "visible",
  },
  dialogFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0",
  },
  dialogFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  instructionNoticeModal: {
    marginBottom: "25px",
  },
  imageNoticeModal: {
    maxWidth: "150px",
  },
  dialogSmall: {
    width: "300px",
  },
  dialogSmallBody: {
    paddingTop: "0",
  },
  dialogSmallFooterFirstButton: {
    margin: "0",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "auto",
  },
  dialogSmallFooterSecondButton: {
    marginBottom: "0",
    marginLeft: "5px",
  },
}));

export default dialogStyle;
