import * as React from "react";

import { TableActions, TableBody } from "hoc";
import { useHistory } from "react-router-dom";
import { FiUserPlus } from "react-icons/fi";
import { Row } from "react-table";
import api from "services/api";
import { RowProps } from "models/views/customer";
import { localeDate } from "utils/mixins";
import { Badge } from "components";

export default function Customer() {
  const history = useHistory();
  const addCustomer = () => {
    history.push("/clientes/novo");
  };
  const deleteCostumer = (original: RowProps) => {
    api.delete(`customers/${original.id}`).then(() => getRecords());
  };
  const editCustomer = (original: RowProps) => {
    history.push(`/clientes/${original.id}/editar`);
  };
  const [customers, setCustomers] = React.useState<any>([]);
  const [columns] = React.useState([
    {
      Header: "Nome",
      Cell: ({ row }: { row: Row<RowProps> }) => row.original.name,
    },
    {
      Header: "E-mail",
      Cell: ({ row }: { row: Row<RowProps> }) =>
        row.original.email ?? <Badge color="gray">Não informado</Badge>,
    },
    {
      Header: "Data de nascimento",
      Cell: ({ row }: { row: Row<RowProps> }) =>
        localeDate(row.original.birth_date),
    },
    {
      Header: "Cidade",
      Cell: ({ row }: { row: Row<RowProps> }) => row.original.address_city,
    },
    {
      Header: "Ações",
      id: "actions",
      Cell: ({ row }: { row: Row<RowProps> }) => (
        <TableActions
          editProps={{ onClick: () => editCustomer(row.original) }}
          deleteProps={{ onClick: () => deleteCostumer(row.original) }}
        />
      ),
    },
  ]);

  const getRecords = () =>
    api.get("customers").then(({ data }) => setCustomers(data));

  React.useEffect(() => {
    getRecords();
  }, []);

  return (
    <TableBody
      buttonProps={{
        onClick: addCustomer,
      }}
      buttonText="adicionar cliente"
      icon={FiUserPlus}
      columns={columns}
      data={customers}
    />
  );
}
