import * as React from "react";
import classNames from "classnames";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { makeStyles } from "@material-ui/core/styles";

import { AppNavbar, Footer, Sidebar } from "components";

import styles from "assets/jss/styles/layouts/appStyle";

import { FiHome, FiUsers } from "react-icons/fi";
import { FaHandHoldingHeart, FaCogs } from "react-icons/fa";

import fullLogo from "assets/img/neo-logo-lettering.png";
import { getUser } from "services/auth";
import { IconType } from "react-icons/lib";

let ps: any;

const useStyles = makeStyles<typeof styles>(styles);

interface Menu {
  path: string;
  name: string;
  layout: string;
  icon: IconType;
  invisible?: boolean;
}

export default function AppLayout(this: any, props: any) {
  const { ...rest } = props;
  const { role_id }: ReturnType<typeof getUser> = { ...getUser() };

  const invisible = role_id === 2;

  const wrapper = React.createRef<HTMLDivElement>();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [menu] = React.useState<Menu[]>([
    {
      path: "home",
      name: "Home",
      layout: "/",
      icon: FiHome,
    },
    {
      path: "clientes",
      name: "Clientes",
      layout: "/",
      icon: FiUsers,
    },
    {
      path: "servicos",
      name: "Serviços",
      layout: "/",
      icon: FaHandHoldingHeart,
    },
    {
      path: "configuracoes",
      name: "Configurações",
      layout: "/",
      icon: FaCogs,
      invisible,
    },
  ]);
  const [color] = React.useState("primary");
  const [bgColor] = React.useState("white");
  const [logo] = React.useState("/favicon-96x96.png");

  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    classNames({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  const mainPanel = React.createRef<any>();

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute: any = (routes: any) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const routesSidebar = (routes: Menu[]) => {
    return routes.filter((route) => {
      if (route.invisible) return null;
      return route;
    });
  };

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.margin = "0";

    return function cleanup() {};
  });

  return (
    <div className={classes.background}>
      <div className={classes.wrapper} ref={wrapper}>
        <Sidebar
          routes={routesSidebar(menu)}
          fullLogo={fullLogo}
          href="https://example.com.br"
          logoText="Neo Soluções"
          logo={logo}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <AppNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(menu)}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>{props.children}</div>
          </div>
          <Footer fluid />
        </div>
      </div>
    </div>
  );
}
