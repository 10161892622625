import React from "react";
import { TextField } from "@material-ui/core";
import { InputProps } from "models/components/input";

const RegularInput = React.forwardRef((props: InputProps | any, ref: any) => {
  const { label, children, muiClasses, ...rest } = props;
  return (
    <TextField
      {...rest}
      invalid={`${props.invalid}`}
      ref={ref}
      classes={muiClasses}
      label={label}
    >
      {children}
    </TextField>
  );
});

RegularInput.defaultProps = {
  variant: "outlined",
  size: "small",
  margin: "dense",
  autoComplete: "off",
  type:"text",
  fullWidth: true,
};

export default RegularInput;
