import React from "react";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { SelectProps } from "models/components/select";

const RegularSelect = React.forwardRef((props: SelectProps, ref: any) => {
  const {
    id,
    label,
    muiClasses,
    data,
    formControlProps,
    labelProps,
    InputProps,
    selectProps,
    helperText,
    error,
    ...rest
  } = props;
  return (
    <FormControl error={error} {...formControlProps}>
      {label !== undefined ? (
        <InputLabel htmlFor={id} {...labelProps}>
          {label}
        </InputLabel>
      ) : null}
      <Select
        ref={ref}
        input={<Input {...InputProps} />}
        {...selectProps}
        {...rest}
      >
        {data.length !== 0 ? (
          data.map(({ label, value, disabled }, key: number) => (
            <MenuItem key={key} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>Nenhum registro encontrado</MenuItem>
        )}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
});

RegularSelect.defaultProps = {
  formControlProps: {
    fullWidth: true,
  },
};

export default RegularSelect;
