import { container, cardTitle, primaryColor } from "assets/jss/styles";

import { createStyles, Theme } from "@material-ui/core";

const loginPageStyle = createStyles((theme: Theme) => ({
  container: {
    zIndex: 100,
    opacity: 0.9,
    ...container,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  card: {
    marginTop: "25%",
  },
  cardTitle: {
    ...cardTitle,
    color: primaryColor[0],
    fontWeight: 500,
    fontSize: "16px",
  },
  fontSize: {
    fontSize: "18px",
  },
  justifyContentLeft: {
    justifyContent: "left !important",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    borderRadius: "10px",
    marginBottom: "20px",
  },
  button: {
    marginRight: "8px",
    fontSize: "18px",
    fontWeight: 500,
    textTransform: "none",
  },
  leftContent: {
    textAlign: "center",
  },
  leftContentImg: {
    borderBottomLeftRadius: "5px",
    borderTopLeftRadius: "5px",
  },
}));

export default loginPageStyle;
