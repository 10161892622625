import * as React from "react";
import { AlertDialog, Button } from "components";
import { Tooltip, Icon } from "@material-ui/core";
import { FiDelete, FiEdit } from "react-icons/fi";
import { IconType } from "react-icons";
import { AlertDialogProps } from "models/components/alert";

interface ITableActions {
  editProps?: {
    text?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    icon?: IconType;
  };
  deleteProps?: {
    text?: string;
    onClick: AlertDialogProps["onConfirm"];
    icon?: IconType;
  };
}

const TableActions = ({ editProps, deleteProps }: ITableActions) => {
  return (
    <React.Fragment>
      {editProps ? (
        <Tooltip title={editProps.text ? editProps.text : "editar"}>
          <Button justIcon textColor="primary" onClick={editProps.onClick}>
            {editProps.icon ? (
              <Icon fontSize="large">
                <editProps.icon />
              </Icon>
            ) : (
              <Icon fontSize="large">
                <FiEdit />
              </Icon>
            )}
          </Button>
        </Tooltip>
      ) : null}
      {deleteProps ? (
        <AlertDialog
          text={
            <Tooltip title={deleteProps.text ? deleteProps.text : "remover"}>
              {deleteProps.icon ? (
                <Icon fontSize="large">
                  <deleteProps.icon />
                </Icon>
              ) : (
                <Icon fontSize="large">
                  <FiDelete />
                </Icon>
              )}
            </Tooltip>
          }
          content="Ao continuar não poderá voltar atrás. Prosseguir?"
          onConfirm={deleteProps.onClick}
          buttonProps={{
            justIcon: true,
            textColor: "danger",
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

export default TableActions;
