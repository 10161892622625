import React from "react";
import classNames from "classnames";

import {
  makeStyles,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Grow,
  Paper,
  Hidden,
  Popper,
  Divider,
  Tooltip,
  IconButton,
  Badge,
  Typography,
  PopperProps,
  ListItemText,
} from "@material-ui/core";

import { CgProfile } from "react-icons/cg";
import { RiNotification3Line, RiSendPlaneFill } from "react-icons/ri";

import { logout } from "store/modules/auth/actions";

import { connect, ConnectedProps } from "react-redux";

import styles from "assets/jss/styles/components/appNavbarLinksStyle";
import { RootState } from "store";
import { useHistory } from "react-router-dom";
import api from "services/api";

import { Notification } from "models/notification";
import { localeFullDate } from "utils/mixins";

const useStyles = makeStyles<typeof styles>(styles);

const UnconnectedAppNavbarLinks: React.FC<HeaderProps> = ({ logout }) => {
  const history = useHistory();

  const classes = useStyles();
  const dropdownItem = classNames(classes.dropdownItem);
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const [openNotification, setOpenNotification] = React.useState<
    PopperProps["anchorEl"] | any
  >(null);

  const [openProfile, setOpenProfile] = React.useState<any>(null);

  const handleLogout = () => {
    logout();
  };

  const handleClickProfile = (event: any) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleProfile = () => {
    return history.replace("/perfil");
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleClickNotification = (event: {
    target: any;
    currentTarget: any;
  }) => {
    if (openNotification?.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const handleNotification = (notification: Notification) => {
    const { id } = notification;

    if (id) api.put(`notifications/${id}`);

    notifications.splice(notifications.indexOf(notification), 1);
    setNotifications([...notifications]);
  };

  const getNotifications = React.useCallback(async () => {
    await api.get("notifications").then(({ data }) => setNotifications(data));
  }, []);

  React.useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const notificationMessage =
    notifications.length === 1
      ? "Você tem 1 notificação"
      : `Você tem ${notifications.length} notificações`;

  return (
    <div className={managerClasses}>
      <IconButton onClick={handleClickNotification} disabled={notifications.length === 0}>
        <Tooltip
          title={notificationMessage}
          disableHoverListener={notifications.length === 0}
          arrow
        >
          <Badge
            variant="dot"
            color="primary"
            invisible={notifications.length === 0}
          >
            <RiNotification3Line />
          </Badge>
        </Tooltip>
      </IconButton>
      <Popper
        open={Boolean(openNotification)}
        anchorEl={openNotification}
        transition
        disablePortal
        placement="right"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "0 0 0" }}>
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseNotification}>
                <MenuList role="menu">
                  <MenuItem className={classes.dropdownItem}>
                    <Typography color="textSecondary">
                      {notificationMessage}
                    </Typography>
                  </MenuItem>
                  {notifications.map((notification, key: number) => (
                    <span key={key}>
                      {key < 5 && (
                        <MenuItem
                          className={classes.dropdownItem}
                          onClick={() => handleNotification(notification)}
                        >
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography>{notification.title}</Typography>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {notification.message}
                                </Typography>
                                <Typography
                                  className={classes.stats}
                                  align="right"
                                >
                                  <RiSendPlaneFill />{" "}
                                  {localeFullDate(notification.created_at)}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </MenuItem>
                      )}
                    </span>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <IconButton onClick={handleClickProfile}>
        <CgProfile />
        <Hidden mdUp implementation="css">
          <span onClick={handleClickProfile} className={classes.linkText}>
            Perfil
          </span>
        </Hidden>
      </IconButton>
      <Popper
        open={Boolean(openProfile)}
        anchorEl={openProfile}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !openProfile,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "0 0 0" }}>
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseProfile}>
                <MenuList role="menu">
                  <MenuItem onClick={handleProfile} className={dropdownItem}>
                    Meus Dados
                  </MenuItem>
                  <Divider light />
                  <MenuItem onClick={handleLogout} className={dropdownItem}>
                    Sair
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return { authenticated: state.auth.authenticated };
};

const mapDispatchToProps = { logout };

const connector = connect(mapStateToProps, mapDispatchToProps);

type HeaderProps = ConnectedProps<typeof connector>;

export default connector(UnconnectedAppNavbarLinks);
