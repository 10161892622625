export function stateReducer(
  state: any,
  { field, value }: { field: string; value: string | boolean }
) {
  return {
    ...state,
    [field]: value,
  };
}

export function localeDate(date: Date) {
  date = new Date(date);
  return date.toDateString();
}

export function localeFullDate(date: Date) {
  date = new Date(date);
  return date.toLocaleString();
}

export function cpfValidator(cpf: string) {
  let sum = 0,
    rest;

  if (cpf.length !== 11 || !Array.from(cpf).filter((e) => e !== cpf[0]).length)
    return false;

  for (let i = 1; i <= 9; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export function removeEmptyFields(data: any) {
  Object.keys(data).forEach((key) => {
    if (data[key] === "" || data[key] == null) {
      delete data[key];
    }
  });
}
