import { cpfValidator } from "utils/mixins";
import { object, string } from "yup";

const customerSchema = object().shape({
  cpf: string().test("is-cpf", "CPF informado não é válido.", (value) =>
    cpfValidator(value ?? "")
  ),
});

export default customerSchema;
