import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Tab as MuiTab, Tabs, Slide } from "@material-ui/core";

import styles from "assets/jss/styles/components/customTabsStyle";
import { TabProps } from "models/components/tab";

const useStyles = makeStyles<typeof styles>(styles);

const Tab: React.FC<TabProps> = React.forwardRef((props, forwardRef: any) => {
  const [active, setActive] = React.useState(props.active);

  const handleChange = (_: React.ChangeEvent<{}>, active: number) => {
    setActive(active);
  };
  const classes = useStyles();
  const { tabs, ...rest } = props;

  const tabButtons = (
    <Tabs value={active} onChange={handleChange} {...rest}>
      {tabs.map((prop, key) => {
        const icon: any = {};
        if (prop.tabIcon)
          icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
        return (
          <MuiTab
            tab={prop.tabName}
            className={classes.tab}
            classes={{
              root: classes.tabRoot,
              wrapper: classes.tabWrapper,
            }}
            label={prop.tabName}
            key={key}
            {...icon}
          />
        );
      })}
    </Tabs>
  );
  const tabContent = (
    <div className={classes.contentWrapper}>
      {tabs.map((prop, key) => (
        <Slide
          key={key}
          direction="left"
          timeout={400}
          in={key === active}
          mountOnEnter
          unmountOnExit
        >
          <div className={classes.tabContent}>
            {key === active && prop.tabContent}
          </div>
        </Slide>
      ))}
    </div>
  );

  return (
    <div ref={forwardRef}>
      {tabButtons}
      {tabContent}
    </div>
  );
});

export default Tab;

Tab.defaultProps = {
  active: 0,
};

Tab.propTypes = {
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.any,
      tabContent: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object,
  }),
};
