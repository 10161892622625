import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  AUTHENTICATION_ERROR,
} from "./actions";

import { CommonAction } from "store";

type AuthState = {
  authenticated: boolean | null;
  error: string | null;
};
const initialState: AuthState = { authenticated: null, error: null };

export default function authReducer(
  state = initialState,
  action: CommonAction
) {
  switch (action.type) {
    case AUTHENTICATED:
      return { ...state, authenticated: true };
    case UNAUTHENTICATED:
      return { ...state, authenticated: false };
    case AUTHENTICATION_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
