import React from "react";

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { IconType } from "react-icons/lib";
interface AccordionProps {
  icon: IconType;
  label: string | number;
}

const AccordionList = (prop: AccordionProps) => (
  <ListItem disableGutters dense>
    <ListItemIcon>
      <prop.icon />
    </ListItemIcon>
    <ListItemText primary={prop.label} />
  </ListItem>
);

export default AccordionList;
