import axios from "axios";

const viacep = axios.create({
  baseURL: `https://viacep.com.br/ws/`,
});

const getCep = (cep: string) => {
  return viacep.get(`${cep}/json`);
};

export default getCep;
