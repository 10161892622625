import * as React from "react";

import { Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";

import {
  Breadcrumbs,
  Input,
  InputMask,
  Button,
  AlertDialog,
  Switch,
} from "components";

import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { FormInputs } from "models/views/customer";

import getCep from "services/viacep";
import api from "services/api";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DayjsUtils from "@date-io/dayjs";

import styles from "assets/jss/styles/views/customerStyle";

import { useHistory } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";

import { useDispatch } from "react-redux";
import { showSnackbar } from "store/modules/ui/actions";

import { yupResolver } from "@hookform/resolvers/yup";
import customerSchema from "validation/customer";
import CustomerService from "views/CustomerService";
import { removeEmptyFields } from "utils/mixins";

const useStyles = makeStyles<typeof styles>(styles);

export default function CustomerForm() {
  const { id } = useParams<Record<string, string | undefined>>();
  const dispatch = useDispatch();
  const [customer, setCustomer] = React.useState<FormInputs>({
    id: null,
    email: "",
    name: "",
    address_city: "",
    address_neighborhood: "",
    address_street: "",
    address_state: "",
  });
  const classes = useStyles();

  const history = useHistory();

  React.useEffect(() => {
    if (id)
      api
        .get(`customers/${id}`)
        .then(({ data }) => setCustomer(data))
        //if customer doesn't exist go back to /clientes
        .catch(() => history.push("/clientes"));
  }, [id, history]);

  const onSubmit = (data: { name: string }) => {
    //temporary solution because react-hook-form expresses empty fields as empty strings
    removeEmptyFields(data);
    if (id)
      api
        .put(`customers/${id}`, data)
        .then(() => {
          history.push("/clientes");
          dispatch(
            showSnackbar({
              message: "Cliente atualizado com sucesso",
              severity: "success",
            })
          );
        })
        .catch(({ response: { data } }) =>
          dispatch(
            showSnackbar({
              message: data.message,
              severity: "error",
            })
          )
        );
    else
      api
        .post("customers", data)
        .then(({ data: { id } }) => {
          history.push(`/clientes/${id}/editar`);
          dispatch(
            showSnackbar({
              message: "Cliente cadastrado com sucesso",
              severity: "success",
            })
          );
        })
        .catch(({ response: { data } }) =>
          dispatch(
            showSnackbar({
              message: data.message,
              severity: "error",
            })
          )
        );
  };

  const CustomerForm = ({
    defaultValues,
  }: {
    defaultValues: typeof customer;
  }) => {
    const {
      register,
      handleSubmit,
      control,
      errors,
      reset,
      setValue,
    } = useForm<FormInputs>({
      defaultValues,
      resolver: yupResolver(customerSchema),
    });

    const handleReset = () => {
      reset();
    };

    const findCep = (value: string) => {
      const shouldValidate = true;
      if (value?.length === 8 && !id)
        getCep(value).then(({ data }) => {
          setValue("address_city", data.localidade, {
            shouldValidate,
          });
          setValue("address_neighborhood", data.bairro, {
            shouldValidate,
          });
          setValue("address_street", data.logradouro, {
            shouldValidate,
          });
          setValue("address_state", data.uf, {
            shouldValidate,
          });
        });
    };

    return (
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item container xs={12} sm={12} md={12} lg={12} direction="column">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography color="textSecondary" variant="body2">
              Dados do cliente
            </Typography>
          </Grid>

          <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Input name="name" required inputRef={register} label="Nome" />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Input
                name="email"
                inputRef={register}
                label="E-mail"
                type="email"
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <Controller
                  name="birth_date"
                  control={control}
                  defaultValue={null}
                  render={({ ref, ...rest }) => (
                    <KeyboardDatePicker
                      fullWidth
                      size="small"
                      required
                      autoComplete="off"
                      maxDateMessage="A data não deve ser posterior à data máxima"
                      minDateMessage="A data não deve ser anterior à data mínima"
                      id="date-picker-dialog"
                      label="Data de nascimento"
                      invalidDateMessage="Formato de data inválido"
                      format="DD/MM/YYYY"
                      {...rest}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Controller
                name="cpf"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Input
                    label="CPF"
                    helperText={errors.cpf?.message}
                    error={!!errors.cpf}
                    required
                    InputProps={{
                      inputProps: {
                        format: "###.###.###-##",
                        isNumericString: true,
                      },
                      inputComponent: InputMask,
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Input name="rg" required inputRef={register} label="RG" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                name="how_did_you_hear_about_us"
                inputRef={register}
                label="Como conheceu a Neo?"
                helperText="Uma breve descrição de como nos conheceu. e.g. indicação, pessoa conhecida, através da internet, etc."
                multiline
                rowsMax="3"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider light className={classes.divider} />
            <Typography color="textSecondary" variant="body2">
              Endereço
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Controller
                name="address_zip_code"
                defaultValue=""
                control={control}
                render={({ onChange, value }, props) => (
                  <Input
                    label="CEP"
                    required
                    value={value}
                    onChange={(value: number) => {
                      onChange(value);
                      findCep(value?.toString());
                    }}
                    InputProps={{
                      inputProps: {
                        format: "#####-###",
                      },
                      inputComponent: InputMask,
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Input
                name="address_state"
                required
                inputRef={register}
                label="UF"
                inputProps={{ maxLength: 2 }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Input
                name="address_city"
                required
                inputRef={register}
                InputLabelProps={{ shrink: true }}
                label="Cidade"
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Input
                name="address_neighborhood"
                required
                inputRef={register}
                InputLabelProps={{ shrink: true }}
                label="Bairro"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Input
                name="address_street"
                required
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Rua"
              />
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <Input
                InputLabelProps={{ shrink: true }}
                required
                name="address_number"
                inputRef={register}
                label="No."
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Controller
                name="telephone"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Input
                    label="Tel. fixo"
                    required
                    InputProps={{
                      inputProps: {
                        format: "(##) ####-####",
                      },
                      inputComponent: InputMask,
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Controller
                name="cell_phone"
                defaultValue=""
                control={control}
                render={(props) => (
                  <Input
                    required
                    label="Tel. celular"
                    InputProps={{
                      inputProps: {
                        format: "(##) # ####-####",
                      },
                      inputComponent: InputMask,
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <Controller
                control={control}
                name="is_whatsapp"
                defaultValue={false}
                render={({
                  onChange,
                  onBlur,
                  value,
                  ref,
                }: ControllerRenderProps) => (
                  <Switch
                    formControlLabelProps={{
                      labelPlacement: "top",
                      inputRef: ref,
                    }}
                    onBlur={onBlur}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(e.target.checked)
                    }
                    checked={value}
                    size="small"
                    color="secondary"
                    label={<IoLogoWhatsapp size="1.3em" color="#009F50" />}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Input
                name="property_name"
                required
                inputRef={register}
                label="Nome da propriedade"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Input
                name="property_location"
                inputRef={register}
                label="Localização da propriedade"
              />
            </Grid>
          </Grid>
          {defaultValues?.id && (
            <React.Fragment>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider light className={classes.divider} />
                <Typography color="textSecondary" variant="body2">
                  Serviços
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomerService
                  customer={{ id: defaultValues.id, name: defaultValues.name }}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <Grid item container xs={12} sm={12} md={12} lg={12} justify="flex-end">
          <Button color="primary" type="submit">
            Confirmar
          </Button>

          <AlertDialog
            text="Limpar"
            content="Ao continuar perderá os dados não salvos. Prosseguir?"
            onConfirm={handleReset}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Breadcrumbs
          links={[
            {
              label: "Clientes",
              href: "/clientes",
              color: "inherit",
            },
            {
              label: `${id ? "Editar" : "Novo"} cliente`,
              href: id ? `/clientes/${id}/editar` : "/clientes/novo",
            },
          ]}
        />
      </Grid>
      <CustomerForm defaultValues={customer} />
    </Grid>
  );
}
