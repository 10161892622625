/*eslint-disable*/
import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import {
  withStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Collapse,
  Icon,
  Link,
} from "@material-ui/core";
import sidebarStyle from "assets/jss/styles/components/sidebarStyle";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { SidebarProps, SidebarState } from "models/components/sidebar";

let ps: any;

type SidebarWrapperProps = {
  className?: string;
  headerLinks?: object;
  links?: object;
};
class SidebarWrapper extends React.Component<SidebarWrapperProps | any, {}> {
  sidebarWrapper: any = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current as any, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component<SidebarProps, SidebarState | any> {
  constructor(props: any) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };
  }
  mainPanel: any = React.createRef();

  getCollapseStates = (routes: any) => {
    let initialState = {};
    routes.map((prop: any) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  getCollapseInitialState(routes: any) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  activeRoute = (routeName: string) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  openCollapse(collapse: any) {
    const st: any = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  createLinks = (routes: any) => {
    const { classes, color } = this.props;
    return routes.map((prop: any, key: number) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        const st: any = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          classNames({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });
        const itemText =
          classes.itemText +
          " " +
          classNames({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          classNames({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
          });
        const itemIcon = classes.itemIcon;
        const caret = classes.caret;
        const collapseItemMini = classes.collapseItemMini;
        return (
          <ListItem
            key={key}
            className={classNames(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{prop.mini}</span>
              )}
              <ListItemText
                primary={prop.name}
                secondary={
                  this.state[prop.state] ? (
                    <MdExpandLess className={caret} />
                  ) : (
                    <MdExpandMore className={caret} />
                  )
                }
                disableTypography
                className={classNames(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List
                className={classes.list + " " + classes.collapseList}
                disablePadding
              >
                <ListItemText inset className={classes.nested}>
                  {this.createLinks(prop.views)}
                </ListItemText>
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        classNames({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const collapseItemMini = classes.collapseItemMini;
      const navLinkClasses =
        classes.itemLink +
        " " +
        classNames({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const itemText =
        classes.itemText +
        " " +
        classNames({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        classNames({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
        });
      const itemIcon = classes.itemIcon;
      return (
        <ListItem
          key={key}
          className={classNames(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={classNames(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>{prop.mini}</span>
            )}
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={classNames(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };
  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      href,
      fullLogo,
    } = this.props;
    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );
    const logoNormal =
      classes.logoNormal +
      " " +
      classNames({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalCenter]: fullLogo,
      });
    const logoMini = classes.logoMini;
    const logoClasses =
      classes.logo +
      " " +
      classNames({
        [classes.whiteAfter]: bgColor === "white",
      });
    const brand = fullLogo ? (
      <div className={logoClasses}>
        {this.props.miniActive ? (
          <React.Fragment>
            <Link href={href} target="_blank" className={logoMini}>
              <img src={logo} alt="logo" className={classes.img} />
            </Link>
            <Link
              href={href}
              target="_blank"
              className={logoNormal + " " + classes.logoNormalLeft}
            >
              {logoText}
            </Link>
          </React.Fragment>
        ) : (
          <Link href={href} target="_blank" className={logoNormal}>
            <img src={fullLogo} alt="logo" width="50%" />
          </Link>
        )}
      </div>
    ) : (
      <div className={logoClasses}>
        <Link href={href} target="_blank" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </Link>
        <Link href={href} target="_blank" className={logoNormal}>
          {logoText}
        </Link>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      classNames({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      classNames({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {brand}
            <SidebarWrapper className={sidebarWrapper} links={links} />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor="left"
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper className={sidebarWrapper} links={links} />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}
export default withStyles(sidebarStyle)(Sidebar);
