import axios from "axios";
import { getToken } from "./auth";
import { logout } from "store/modules/auth/actions";

const { REACT_APP_URL, NODE_ENV } = process.env;

const api = axios.create({
  baseURL: NODE_ENV === "production" ? `${REACT_APP_URL}/api` : "http://localhost:4000",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

let sessionRetry = false;

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response.status === 401 && response.data.message === "jwt expired" && !sessionRetry) {
      sessionRetry = true;
      alert("A sessão atual expirou ou o usuário foi desativado.");
      logout();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
