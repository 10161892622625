import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { makeStyles, AppBar, Toolbar, Hidden, Icon } from "@material-ui/core";

import { RiMenuFoldLine, RiMenuUnfoldLine, RiMenu3Line } from "react-icons/ri";

import AdminNavbarLinks from "./AppLinks";
import { Button } from "components";

import styles from "assets/jss/styles/components/appNavbarStyle";

const useStyles = makeStyles<typeof styles>(styles);

interface AppNavbarProps {
  color?: string;
  text?: string;
  brandText?: string;
  miniActive: boolean;
  sidebarMinimize: boolean;
  handleDrawerToggle: (...args: any[]) => any;
}

export default function AppNavbar(props: AppNavbarProps) {
  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = classNames({
    [" " + classes[color ?? "default"]]: color,
  });
  const sidebarMinimize = classes.sidebarMinimize;
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <Icon>
                  <RiMenuUnfoldLine className={classes.sidebarMiniIcon} />
                </Icon>
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <Icon>
                  <RiMenuFoldLine className={classes.sidebarMiniIcon} />
                </Icon>
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {brandText ? <Button>{brandText}</Button> : null}
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Icon fontSize="large">
              <RiMenu3Line />
            </Icon>
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AppNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
