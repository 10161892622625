import { Grid, Typography } from "@material-ui/core";
import { Accordion, Button, Input } from "components";
import { AccordionList } from "hoc";
import { useForm } from "react-hook-form";
import { RiLockPasswordLine, RiProfileLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import api from "services/api";
import { getUser, setUser } from "services/auth";
import { showSnackbar } from "store/modules/ui/actions";

export default function Profile() {
  const { id, name, email } = getUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    errors,
    clearErrors,
    setError,
    register,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name,
      email,
      password: null,
      oldPassword: null,
      newPassword: null,
    },
    mode: "onChange",
  });
  const onSubmit = (data: ReturnType<typeof getUser>) => {
    const {
      name,
      email,
      password,
      confirmPassword,
      oldPassword: old_password,
    } = data;

    if (password !== confirmPassword)
      setError("password", {
        type: "manual",
        message: "A senha informada não confere.",
      });
    else
      api
        .put(`users/${id}`, { name, email, password, old_password })
        .then(({ data }) => {
          clearErrors("password");
          setUser(data);
          history.push("/home");
          dispatch(
            showSnackbar({
              message: "Dados atualizados com sucesso",
              severity: "success",
            })
          );
        })
        .catch(({ response: { data: err } }) =>
          setError("password", { type: "manual", message: err.message })
        );
  };

  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      component="form"
      direction="column"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Accordion
        bgColor="white"
        color="primary"
        active={0}
        collapses={[
          {
            title: (
              <AccordionList icon={RiProfileLine} label="Dados da conta" />
            ),
            content: (
              <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                  <Input
                    name="name"
                    required
                    inputRef={register({ required: true })}
                    label="Nome"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Input
                    name="email"
                    required
                    inputRef={register}
                    label="E-mail"
                    type="email"
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            title: (
              <AccordionList icon={RiLockPasswordLine} label="Credenciais" />
            ),
            content: (
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                spacing={1}
                direction="column"
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Input
                    name="oldPassword"
                    inputRef={register}
                    label="Senha antiga"
                    type="password"
                    helperText="Para alterar a sua senha atual você precisa informar a senha antiga."
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Input
                    name="password"
                    inputRef={register}
                    type="password"
                    label="Nova senha"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Input
                    name="confirmPassword"
                    inputRef={register}
                    type="password"
                    label="Confirmar nova senha"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography color="error" variant="body2">
                    {errors.password?.message}
                  </Typography>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Button color="primary" type="submit" disabled={!formState.isDirty}>
          Salvar alterações
        </Button>
      </Grid>
    </Grid>
  );
}
