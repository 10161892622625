import Route from "./Route";
import { BrowserRouter, Switch } from "react-router-dom";

import Login from "views/pages/Login";
import NotFound from "views/pages/NotFound";
import Profile from "views/pages/Profile";
import Home from "views/Home";
import Customer from "views/Customer";
import CustomerForm from "views/Customer/form";
import Service from "views/Service";
import Settings from "views/Settings"

export default function Routes() {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/esqueci-senha/:token" exact component={Login} />
        <Route path="/home" exact component={Home} isPrivate />
        <Route path="/clientes" exact component={Customer} isPrivate />
        <Route path="/clientes/novo" exact component={CustomerForm} isPrivate />
        <Route
          path="/clientes/:id/editar"
          exact
          component={CustomerForm}
          isPrivate
        />
        <Route path="/servicos" exact component={Service} isPrivate />
        <Route path="/configuracoes" exact component={Settings} isPrivate admin/>
        <Route path="/perfil" exact component={Profile} isPrivate />

        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
