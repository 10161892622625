import * as React from "react";

import { Snackbar as MuiSnackbar } from "@material-ui/core";

import { Alert as MuiAlert, AlertProps } from "@material-ui/lab";
import { connect } from "react-redux";
import { clearSnackbar } from "store/modules/ui/actions";
import { RootState } from "store";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UnconnectedSnackbar: React.FC = ({
  successSnackbarMessage,
  successSnackbarOpen,
  clearSnackbar,
}: any) => {
  function handleClose() {
    clearSnackbar();
  }

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={successSnackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert severity={successSnackbarMessage.severity}>
        {successSnackbarMessage.message}
      </Alert>
    </MuiSnackbar>
  );
};

const mapDispatchToProps = { clearSnackbar };

const mapStateToProps = (state: RootState) => {
  const { successSnackbarMessage, successSnackbarOpen } = state.ui;
  return {
    successSnackbarMessage,
    successSnackbarOpen,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedSnackbar);
