import * as React from "react";
import { FaBirthdayCake, FaRegClock } from "react-icons/fa";

import {
  Collapse,
  Grid,
  makeStyles,
  Tooltip,
  List,
  Button,
} from "@material-ui/core";
import {
  Card,
  CardFooter,
  CardHeader,
  CardIcon,
  Table,
  Badge,
} from "components";

import styles from "assets/jss/styles/views/homeStyle";
import { HomeCardProps } from "models/views/home";
import { RowProps as CustomerInput } from "models/views/customer";
import { RowProps as ServicesInput } from "models/views/service";

import { Row } from "react-table";
import api from "services/api";
import { localeDate } from "utils/mixins";
import { maskMobilePhone, maskPhone } from "utils/masks";
import { TableActions } from "hoc";
import { FiEye } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { severity } from "assets/jss/styles";
import { IoLogoWhatsapp, IoMdCall } from "react-icons/io";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles<typeof styles>(styles);

export default function Home() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const [deadlines, setDeadlines] = React.useState<
    ServicesInput[] & CustomerInput[]
  >([]);
  const [serviceColumns] = React.useState([
    {
      Header: "Nome",
      Cell: ({ row }: { row: Row<CustomerInput> }) =>
        row.original.customer?.name,
    },
    {
      Header: "Serviço",
      Cell: ({ row }: { row: Row<ServicesInput> }) =>
        row.original.service?.name,
    },
    {
      Header: "Vencimento",
      Cell: ({ row }: { row: Row<CustomerInput> }) => (
        <Badge color={severity[row.original.virtual_severity]}>
          <Tooltip
            arrow
            title={`faltam: ${row.original.virtual_days_to_expire}`}
          >
            <span>{row.original.virtual_expires}</span>
          </Tooltip>
        </Badge>
      ),
    },
    {
      Header: "Ações",
      id: "actions",
      Cell: ({ row }: { row: Row<CustomerInput> }) => (
        <TableActions
          editProps={{
            icon: FiEye,
            onClick: () =>
              history.push(`clientes/${row.original.customer.id}/editar`),
            text: "Visualizar",
          }}
        />
      ),
    },
  ]);
  const [birthdays, setBirthdays] = React.useState<CustomerInput[]>([]);
  const [customerColumns] = React.useState([
    {
      Header: "Nome",
      Cell: ({ row }: { row: Row<CustomerInput> }) => row.original.name,
    },
    {
      Header: "Contato",
      Cell: ({ row }: { row: Row<CustomerInput> }) => (
        <span>
          {maskMobilePhone(row.original.cell_phone) ??
            maskPhone(row.original.telephone)}{" "}
          {row.original.is_whatsapp ? (
            <IoLogoWhatsapp size="1.3em" color="#009F50" />
          ) : (
            <IoMdCall size="1.3em" />
          )}
        </span>
      ),
    },
    {
      Header: "Data de nascimento",
      Cell: ({ row }: { row: Row<CustomerInput> }) =>
        localeDate(row.original.birth_date),
    },
  ]);

  const [notIssued, setNotIssued] = React.useState<any>([]);
  const [notIssuedColumns] = React.useState([
    {
      Header: "Nome",
      Cell: ({ row }: { row: Row<CustomerInput> }) =>
        row.original.customer?.name,
    },
    {
      Header: "Serviço",
      Cell: ({ row }: { row: Row<ServicesInput> }) =>
        row.original.service?.name,
    },
    {
      Header: "Data do requerimento",
      Cell: ({ row }: { row: Row<CustomerInput> }) => (
        <Badge color="success">{localeDate(row.original.created_at)}</Badge>
      ),
    },
    {
      Header: "Data de expedição(orgão)",
      Cell: () => <Badge color="danger">Não expedido</Badge>,
    },
    {
      Header: "Ações",
      id: "actions",
      Cell: ({ row }: { row: Row<CustomerInput> }) => (
        <TableActions
          editProps={{
            icon: FiEye,
            onClick: () =>
              history.push(`clientes/${row.original.customer.id}/editar`),
            text: "Visualizar",
          }}
        />
      ),
    },
  ]);

  const getBirthdays = async () =>
    await api.get("/home/birthdays").then(({ data }) => setBirthdays(data));

  const getExpirations = async () =>
    await api.get("/home/deadlines").then(({ data }) => setDeadlines(data));

  const getNotIssued = async () =>
    await api.get("/home/not-issued").then(({ data }) => setNotIssued(data));

  React.useEffect(() => {
    getBirthdays();
    getExpirations();
    getNotIssued();
  }, []);

  const HomeCard = (props: HomeCardProps) => (
    <Card>
      <CardHeader stats icon>
        <CardIcon color={props.color}>
          <props.cardIcon />
        </CardIcon>
        <p className={classes.cardCategory}>{props.category}</p>
        <h3 className={classes.cardTitle}>{props.title}</h3>
      </CardHeader>
      <CardFooter>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.stats}>{props.statsText}</div>
          </Grid>
        </Grid>
      </CardFooter>
    </Card>
  );
  return (
    <Grid container spacing={2}>
      {notIssued.length !== 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Alert
            variant="standard"
            severity="info"
            action={
              <Button color="inherit" size="small" onClick={handleClick}>
                {open ? "esconder" : "ver mais"}
              </Button>
            }
          >
            {notIssued.length}{" "}
            {notIssued.length === 1 ? "serviço aguarda" : "serviços aguardam"}{" "}
            expedição.
          </Alert>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.stats}>
              <Table columns={notIssuedColumns} data={notIssued} />
            </List>
          </Collapse>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <HomeCard
          cardIcon={FaBirthdayCake}
          category="Próximos aniversariantes"
          title={`${
            birthdays?.length
              ? `${birthdays.length} ${
                  birthdays.length === 1 ? "cliente" : "clientes"
                }`
              : "não há aniversários próximos"
          }`}
          statsText={<Table columns={customerColumns} data={birthdays} />}
          color="rose"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <HomeCard
          cardIcon={FaRegClock}
          category="Próximos vencimentos"
          title={`${
            deadlines?.length
              ? `${deadlines.length} ${
                  deadlines.length === 1 ? "cliente" : "clientes"
                }`
              : "não há vencimentos próximos"
          }`}
          statsText={<Table columns={serviceColumns} data={deadlines} />}
          color="danger"
        />
      </Grid>
    </Grid>
  );
}
