import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/styles/components/badgeStyle";

const useStyles = makeStyles(styles);

interface IBadge {
  color: "primary" | "warning" | "danger" | "success" | "info" | "gray";
  children: string | React.InputHTMLAttributes<HTMLInputElement>;
}

export default function Badge(props: IBadge) {
  const { color, children } = props;
  const classes = useStyles();
  return (
    <span className={classes.badge + " " + classes[color]}>{children}</span>
  );
}

Badge.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "gray",
  ]),
  children: PropTypes.node,
};
