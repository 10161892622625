export const APP_KEY = "@neo-solucoes";

export const isAuthenticated = () =>
  localStorage.getItem(`${APP_KEY}-token`) !== null;
export const getToken = () => localStorage.getItem(`${APP_KEY}-token`);
export const signIn = (token: string) =>
  localStorage.setItem(`${APP_KEY}-token`, token);
export const setUser = (user: object) =>
  localStorage.setItem(`${APP_KEY}-user`, JSON.stringify(user));
export const getUser = () =>
  JSON.parse(localStorage.getItem(`${APP_KEY}-user`) as string);
export const signOut = () => localStorage.clear();
