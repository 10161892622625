import { Link } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import styles from "assets/jss/styles/components/footerStyle";

const { REACT_APP_VERSION } = process.env;
const useStyles = makeStyles(styles);

interface FooterProps {
  fluid?: boolean;
  white?: boolean;
};

export default function Footer(props: FooterProps) {
  const classes = useStyles();
  const { fluid, white } = props;

  const container = classNames({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p className={classes.right}>
          v{REACT_APP_VERSION} | &copy; {new Date().getFullYear()} Neo Soluções,
          desenvolvido por{" "}
          <Link
            color="textSecondary"
            href="https://korubos.com.br"
            target="_blank"
          >
            Korubos
          </Link>{" "}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
};
