import * as React from "react";

import { TableActions } from "hoc";
import { Row } from "react-table";
import { Dialog, Input, Table } from "components";
import { Grid } from "@material-ui/core";
import { FormInputs, RowProps } from "models/views/role";
import { useForm } from "react-hook-form";
import api from "services/api";

const initialValues = {
  id: null,
  name: null,
  duration_time: null,
};

export default function Role() {
  const [role, setRole] = React.useState<FormInputs>();
  const [open, setOpen] = React.useState(false);

  const editService = (original: RowProps) => {
    setRole(original);
    setOpen(true);
  };
  const [roles, setRoles] = React.useState<any>([]);
  const [columns] = React.useState([
    {
      Header: "Nome",
      Cell: ({ row }: { row: Row<RowProps> }) => row.original.name,
    },
    {
      Header: "Ações",
      id: "actions",
      Cell: ({ row }: { row: Row<RowProps> }) => (
        <TableActions
          editProps={{ onClick: () => editService(row.original) }}
        />
      ),
    },
  ]);

  const getRecords = () => api.get("roles").then(({ data }) => setRoles(data));

  React.useEffect(() => {
    getRecords();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setRole(initialValues);
  };

  const onSubmit = (data: FormInputs) => {
    if (role?.id) api.put(`roles/${role.id}`, data).then(() => getRecords());

    handleClose();
  };

  const ServiceForm = ({ defaultValues }: { defaultValues: typeof role }) => {
    const { register, handleSubmit } = useForm<FormInputs>({
      defaultValues,
    });

    return (
      <Dialog
        width="md"
        fullWidth
        confirmText="Confirmar"
        noCancelButton
        title={`${role?.id ? "editar" : "adicionar"} papel`}
        color="primary"
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                name="name"
                required
                inputRef={register({ required: true })}
                label="Nome"
              />
            </Grid>
          </Grid>
        }
        onClose={handleClose}
        onConfirm={handleSubmit(onSubmit)}
        open={open}
      />
    );
  };

  return (
    <React.Fragment>
      <ServiceForm defaultValues={role} />
      <Table columns={columns} data={roles} />
    </React.Fragment>
  );
}
