import { Grid, makeStyles } from "@material-ui/core";
import * as React from "react";

import styles from "assets/jss/styles/views/notFoundStyle";

const useStyles = makeStyles<typeof styles>(styles);

const NotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentCenter}>
      <Grid container>
        <Grid item md={12}>
          <h1 className={classes.title}>404</h1>
          <h2 className={classes.subTitle}>Página não encontrada :(</h2>
          <h4 className={classes.description}>
            Oops! Parece que essa página não está disponível ou não existe.
          </h4>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
