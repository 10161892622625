import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { AUTHENTICATED } from "./store/modules/auth/actions";
import { isAuthenticated } from "services/auth";
import { rootReducer } from "./store";
import Routes from "./routes";
import Snackbar from "components/Snackbar";

const store = createStore(rootReducer, applyMiddleware(thunk));

if (isAuthenticated()) {
  store.dispatch({ type: AUTHENTICATED });
}

function App() {
  return (
    <Provider store={store}>
      <Snackbar />
      <Routes />
    </Provider>
  );
}

export default App;
