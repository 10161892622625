import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { Button } from "components";
import { AlertDialogProps } from "models/components/alert";

export default function AlertDialog(props: AlertDialogProps) {
  const { text, content, onConfirm, buttonProps } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    handleClose();
    onConfirm();
  };
  const handleDisagree = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} {...buttonProps}>
        {text}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDisagree}
            color="transparent"
            textColor="secondary"
          >
            Não, voltar
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
