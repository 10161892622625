import { Badge, Dialog, Select } from "components";
import * as React from "react";
import api from "services/api";
import {
  FormInputs,
  RowProps as CustomerServiceInput,
} from "models/views/service-customer";
import { RowProps as ServiceInput } from "models/views/service";
import { Controller, useForm } from "react-hook-form";
import { Row } from "react-table";
import { localeDate } from "utils/mixins";
import { RiServiceLine } from "react-icons/ri";
import { severity } from "assets/jss/styles";
import { Grid, Tooltip } from "@material-ui/core";

import DayjsUtils from "@date-io/dayjs";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { TableBody, TableActions } from "hoc";
import { yupResolver } from "@hookform/resolvers/yup";
import customerServiceSchema from "validation/customerServiceSchema";

const initialValues = {
  id: null,
  service_id: "",
};

export default function CustomerService(props: any) {
  const { customer } = props;
  const [services, setServices] = React.useState<ServiceInput[]>([]);
  const [open, setOpen] = React.useState(false);
  const [customerService, setCustomerService] = React.useState<FormInputs>(
    initialValues
  );
  const [customerServices, setCustomerServices] = React.useState<
    CustomerServiceInput[]
  >([]);

  const handleClose = () => {
    setOpen(false);
    setCustomerService(initialValues);
  };

  const onSubmit = (data: FormInputs) => {
    const { issued_in } = data;
    if (customerService.id)
      api
        .put(`/customers/services/${customerService.id}`, { issued_in })
        .then(() => getCustomerServices());
    else
      api
        .post(`/customers/${customer.id}/services/`, data)
        .then(() => getCustomerServices());
    handleClose();
  };

  const getServices = React.useCallback(
    async () => await api.get("services").then(({ data }) => setServices(data)),
    []
  );

  const getCustomerServices = React.useCallback(async () => {
    await api
      .get(`/customers/${customer.id}/services`)
      .then(({ data }) => setCustomerServices(data));
  }, [customer.id]);

  const CustomerServiceForm = ({
    defaultValues,
  }: {
    defaultValues: typeof customerService;
  }) => {
    const { handleSubmit, control, errors } = useForm<FormInputs>({
      defaultValues,
      resolver: yupResolver(customerServiceSchema),
    });

    return (
      <Dialog
        width="md"
        fullWidth
        confirmText="Confirmar"
        noCancelButton
        title={` ${customer.name} - ${
          defaultValues?.id ? "editar" : "novo"
        } serviço`}
        color="primary"
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="service_id"
                control={control}
                defaultValue=""
                render={({ ref, ...rest }) => (
                  <Select
                    label="Serviço"
                    helperText={errors.service_id?.message}
                    error={!!errors.service_id}
                    disabled={!!defaultValues?.id}
                    data={services.map(({ id, name }) => {
                      const label = customerServices.find(
                        (service) => service.service_id === id
                      )
                        ? defaultValues?.id
                          ? name
                          : `${name} (renovar)`
                        : name;

                      return { value: id, label };
                    })}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <Controller
                  name="issued_in"
                  control={control}
                  defaultValue={null}
                  render={({ ref, ...rest }) => (
                    <KeyboardDatePicker
                      fullWidth
                      size="small"
                      autoComplete="off"
                      id="date-picker-expedition-dialog"
                      label="Data de expedição(orgão)"
                      format="DD/MM/YYYY"
                      helperText={
                        !defaultValues?.issued_in &&
                        "Deixar em branco se ainda não existir data de expedição pelo orgão."
                      }
                      {...rest}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        }
        onClose={handleClose}
        onConfirm={handleSubmit(onSubmit)}
        open={open}
      />
    );
  };

  React.useEffect(() => {
    getCustomerServices();
  }, [getCustomerServices]);

  const addServiceToCustomer = React.useCallback(async () => {
    await getServices();
    setOpen(true);
  }, [getServices]);

  const editServiceFromCustomer = React.useCallback(
    async (original: CustomerServiceInput) => {
      await getServices();
      setCustomerService(original);
      setOpen(true);
    },
    [getServices]
  );

  const deleteServiceFromCustomer = React.useCallback(
    async (original: CustomerServiceInput) => {
      await api
        .delete(`/customers/services/${original.id}`)
        .then(() => getCustomerServices());
    },
    [getCustomerServices]
  );

  const [columns] = React.useState([
    {
      Header: "Nome",
      Cell: ({ row }: { row: Row<ServiceInput> }) => row.original.name,
    },
    {
      Header: "Validade(meses)",
      Cell: ({ row }: { row: Row<ServiceInput> }) => row.original.duration_time,
    },
    {
      Header: "Data do requerimento",
      Cell: ({ row }: { row: Row<CustomerServiceInput> }) => (
        <Badge color="gray">{localeDate(row.original.created_at)}</Badge>
      ),
    },
    {
      Header: "Data de expedição(orgão)",
      Cell: ({ row }: { row: Row<CustomerServiceInput> }) => (
        <Badge color={row.original.issued_in ? "gray" : "danger"}>
          {row.original.issued_in
            ? localeDate(row.original.issued_in)
            : "Não expedido"}
        </Badge>
      ),
    },
    {
      Header: "Data de vencimento",
      Cell: ({ row }: { row: Row<CustomerServiceInput> }) => (
        <Badge color={severity[row.original.virtual_severity] ?? "danger"}>
          {row.original.virtual_expires === "expirado" ? (
            <Tooltip
              arrow
              title={`expirado em: ${localeDate(row.original.expires_in)}`}
            >
              <span>{row.original.virtual_expires}</span>
            </Tooltip>
          ) : row.original.expires_in ? (
            ` ${localeDate(row.original.expires_in)} | ${
              row.original.virtual_days_to_expire
            }`
          ) : (
            "Não expedido"
          )}
        </Badge>
      ),
    },
    {
      Header: "Ações",
      id: "actions",
      Cell: ({ row }: { row: Row<CustomerServiceInput> }) => (
        <TableActions
          editProps={{ onClick: () => editServiceFromCustomer(row.original) }}
          deleteProps={{
            onClick: () => deleteServiceFromCustomer(row.original),
          }}
        />
      ),
    },
  ]);

  return (
    <React.Fragment>
      <CustomerServiceForm defaultValues={customerService} />

      <TableBody
        buttonProps={{
          onClick: addServiceToCustomer,
        }}
        buttonText="adicionar serviço ao cliente"
        icon={RiServiceLine}
        columns={columns}
        data={customerServices}
      />
    </React.Fragment>
  );
}
