import { CustomMaskProps } from "models/components/inputmask";
import NumberFormat, { NumberFormatValues } from "react-number-format";

export default function CustomMask(props: CustomMaskProps) {
  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={(target: NumberFormatValues) => {
        if (target) onChange(target.value);
      }}
      {...rest}
    />
  );
}
