import { hexToRgb, blackColor, whiteColor } from "assets/jss/styles";

import { createStyles, Theme } from "@material-ui/core";

import bgImg from "assets/img/login-background.jpg";

const authStyle = createStyles((theme: Theme) => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0",
  },
  fullPage: {
    padding: "30px 30px 15px",
    height: "100vh",
    color: whiteColor,
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 5px",
    },
    "&:before": {
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)",
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "0",
    },
  },
  background: {
    background: `url(${bgImg})`,
  },
}));

export default authStyle;
