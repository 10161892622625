import { createStyles } from "@material-ui/core";
import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  grayColor,
  whiteColor,
  hexToRgb,
} from "assets/jss/styles";

const badgeStyle = createStyles({
  badge: {
    borderRadius: "3px",
    padding: "5px 12px",
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "1",
    color: whiteColor,
    textAlign: "center",
    verticalAlign: "baseline",
    display: "inline-block",
  },
  primary: {
    backgroundColor: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.6)",
  },
  warning: {
    backgroundColor: "rgba(" + hexToRgb(warningColor[0]) + ", 0.6)",
  },
  danger: {
    backgroundColor: "rgba(" + hexToRgb(dangerColor[0]) + ", 0.6)",
  },
  success: {
    backgroundColor: "rgba(" + hexToRgb(successColor[0]) + ", 0.6)",
  },
  info: {
    backgroundColor: "rgba(" + hexToRgb(infoColor[0]) + ", 0.6)",
  },
  gray: {
    backgroundColor: "rgba(" + hexToRgb(grayColor[0]) + ", 0.6)",
  },
});

export default badgeStyle;
