import { Link, Breadcrumbs } from "@material-ui/core";
import { BreadcrumbProps } from "models/components/breadcrumbs";

export default function CustomBreadcrumbs(props: BreadcrumbProps) {
  const { links, ...rest } = props;
  return (
    <Breadcrumbs {...rest}>
      {links.map(({ label, color, href, onClick }, index: number) => (
        <Link key={index} color={color} href={href} {...onClick}>
          {label}
        </Link>
      ))}
    </Breadcrumbs>
  );
}
