import User from "views/User";
import Role from "views/Role";

import { Tab } from "components";
import { FiUser, FiShield } from "react-icons/fi";

export default function Settings() {
  return (
    <Tab
      indicatorColor="primary"
      textColor="primary"
      tabs={[
        { 
          tabIcon: FiUser,
          tabName: "Usuários",
          tabContent: <User />,
        },
        {
          tabIcon: FiShield,
          tabName: "Papéis",
          tabContent: <Role />,
        },
      ]}
    />
  );
}
