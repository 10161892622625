import * as React from "react";
import { makeStyles } from "@material-ui/core";

import styles from "assets/jss/styles/layouts/authStyle";

const useStyles = makeStyles<typeof styles>(styles);

interface AuthProps {
  children: string | React.InputHTMLAttributes<HTMLInputElement>;
}

export default function AuthLayout(props: AuthProps) {
  const classes = useStyles();
  const wrapper = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.margin = "0";

    return function cleanup() {};
  });

  return (
    <div className={classes.background}>
      <div className={classes.wrapper} ref={wrapper}>
        <div className={classes.fullPage}>{props.children}</div>
      </div>
    </div>
  );
}
