import * as React from "react";

import { TableActions, TableBody } from "hoc";
import { FiUserPlus } from "react-icons/fi";
import { Row } from "react-table";
import { Dialog, Input, Select, Badge } from "components";
import { Grid, Typography } from "@material-ui/core";
import { FormInputs, RowProps } from "models/views/user";
import { Controller, useForm } from "react-hook-form";
import api from "services/api";

const initialValues = {
  id: null,
  name: null,
  email: null,
  password: null,
  role_id: undefined,
};

export default function User() {
  const [roles, setRoles] = React.useState([]);
  const [user, setUser] = React.useState<FormInputs>();
  const [open, setOpen] = React.useState(false);
  const addUser = async () => {
    await getRoles();
    setOpen(true);
  };
  const deleteUser = async (original: RowProps) => {
    await api.delete(`users/${original.id}`).then(() => getRecords());
  };
  const editUser = async (original: RowProps) => {
    await getRoles();
    setUser(original);
    setOpen(true);
  };
  const [users, setUsers] = React.useState<any>([]);
  const [columns] = React.useState([
    {
      Header: "Nome",
      Cell: ({ row }: { row: Row<RowProps> }) => row.original.name,
    },
    {
      Header: "E-mail",
      Cell: ({ row }: { row: Row<RowProps> }) => row.original.email,
    },
    {
      Header: "Papel",
      Cell: ({ row }: { row: Row<RowProps> }) => (
        <Badge color={row.original.role?.id === 1 ? "danger" : "info"}>
          {row.original.role?.name ?? ""}
        </Badge>
      ),
    },
    {
      Header: "Ações",
      id: "actions",
      Cell: ({ row }: { row: Row<RowProps> }) => (
        <TableActions
          editProps={{ onClick: () => editUser(row.original) }}
          deleteProps={{ onClick: () => deleteUser(row.original) }}
        />
      ),
    },
  ]);

  const getRoles = async () =>
    await api.get("roles").then(({ data }) => setRoles(data));

  const getRecords = async () =>
    await api.get("users").then(({ data }) => setUsers(data));

  React.useEffect(() => {
    getRecords();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setUser(initialValues);
  };
  
  const UserForm = ({ defaultValues }: { defaultValues: typeof user }) => {
    const {
      register,
      handleSubmit,
      errors,
      setError,
      control,
    } = useForm<FormInputs>({
      defaultValues,
    });

    const onSubmit = (data: FormInputs) => {
      const { name, email, password, role_id, confirmPassword } = data;

      if (password !== confirmPassword)
        setError("password", {
          type: "manual",
          message: "A senha informada não confere.",
        });
      else if (user?.id)
        api
          .put(`users/${user.id}`, { name, email, password, role_id })
          .then(() => {
            getRecords();
            handleClose();
          });
      else
        api.post("users", { name, email, password, role_id }).then(() => {
          getRecords();
          handleClose();
        });
    };
    return (
      <Dialog
        width="md"
        fullWidth
        confirmText="Confirmar"
        noCancelButton
        title={`${user?.id ? "editar" : "adicionar"} usuário`}
        color="primary"
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                name="name"
                required
                inputRef={register({ required: true })}
                label="Nome"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                name="email"
                required
                inputRef={register({ required: true })}
                label="E-mail"
                helperText={
                  !user?.id &&
                  "Este será o e-mail de acesso e recuperação de senha."
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="role_id"
                control={control}
                defaultValue=""
                render={({ ref, ...rest }) => (
                  <Select
                    label="Papel"
                    data={roles.map(({ id, name }) => {
                      return {
                        value: id,
                        label: name,
                      };
                    })}
                    {...rest}
                  />
                )}
              />
            </Grid>
            {!user?.id && (
              <React.Fragment>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Input
                    required
                    name="password"
                    inputRef={register({ required: true })}
                    type="password"
                    label="Senha de acesso"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Input
                    required
                    name="confirmPassword"
                    inputRef={register({ required: true })}
                    type="password"
                    label="Confirmar senha"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography color="error" variant="body2">
                    {errors.password?.message}
                  </Typography>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        }
        onClose={handleClose}
        onConfirm={handleSubmit(onSubmit)}
        open={open}
      />
    );
  };

  return (
    <React.Fragment>
      <UserForm defaultValues={user} />
      <TableBody
        buttonProps={{
          onClick: addUser,
        }}
        buttonText="adicionar usuário"
        icon={FiUserPlus}
        columns={columns}
        data={users}
      />
    </React.Fragment>
  );
}
